import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

function AutoRefresh({ refreshInterval }) {
  const [timeLeft, setTimeLeft] = useState(refreshInterval);
  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(false);

  useEffect(() => {
    let intervalId;
    if (autoRefreshEnabled) {
      intervalId = setInterval(() => {
        if (timeLeft === 0) {
          window.location.reload(); // Full page refresh
        } else {
          setTimeLeft(timeLeft - 1);
        }
      }, 1000); // Decrement countdown every second
    }

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [timeLeft, refreshInterval, autoRefreshEnabled]);

  const handleToggle = () => {
    setAutoRefreshEnabled(!autoRefreshEnabled);
    setTimeLeft(refreshInterval); // Reset the countdown whenever toggled
  };

  return (
    <div style={styles.container}>
      <div style={styles.countdown}>
        {autoRefreshEnabled
          ? `Refreshing in ${timeLeft} seconds`
          : 'Auto-refresh disabled'}
      </div>
      <Form.Check 
        type="switch"
        id="auto-refresh-toggle"
        label="Auto-refresh"
        checked={autoRefreshEnabled}
        onChange={handleToggle}
        style={styles.switch}
      />
    </div>
  );
}

const styles = {
  container: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    borderRadius: '5px',
  },
  countdown: {
    marginBottom: '5px',
  },
  switch: {
    color: 'white',
  },
};

export default AutoRefresh;
