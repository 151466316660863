import React from 'react';
import { Accordion, Table } from 'react-bootstrap';

// Function to calculate the day's summary
const calculateDaySummary = (admissionsForDate) => {
  let total = 0;
  let countByType = { $15: 0, $10: 0, $5: 0 };
  admissionsForDate.forEach((admission) => {
    const type = admission.type;
    const price = parseInt(type.replace('$', ''), 10);
    total += price;
    countByType[type] += 1;
  });
  return { total, countByType };
};

// Function to group admissions by date
const groupAdmissionsByDate = (admissions) => {
  const grouped = admissions.reduce((acc, admission) => {
    const date = new Date(admission.time).toLocaleDateString(); // Get date string for grouping
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(admission);
    return acc;
  }, {});
  return Object.entries(grouped).sort((a, b) => new Date(b[0]) - new Date(a[0])); // Sort by date, newest first
};

const PreviousSummaries = ({ allAdmissions }) => {
  return (
    <Accordion defaultActiveKey="none">
      {groupAdmissionsByDate(allAdmissions).map(([date, admissionsForDate], index) => {
        const { total, countByType } = calculateDaySummary(admissionsForDate);
        return (
          <Accordion.Item eventKey={String(index)} key={date}>
            <Accordion.Header>
              {date} - Total: ${total} ({admissionsForDate.length} Admissions)
            </Accordion.Header>
            <Accordion.Body>
              <h4>Summary for {date}</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Count</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>$15 Admissions</td>
                    <td>{countByType['$15']}</td>
                    <td>${countByType['$15'] * 15}</td>
                  </tr>
                  <tr>
                    <td>$10 Admissions</td>
                    <td>{countByType['$10']}</td>
                    <td>${countByType['$10'] * 10}</td>
                  </tr>
                  <tr>
                    <td>$5 Admissions</td>
                    <td>{countByType['$5']}</td>
                    <td>${countByType['$5'] * 5}</td>
                  </tr>
                  <tr>
                    <td><strong>Grand Total</strong></td>
                    <td><strong>{admissionsForDate.length}</strong></td>
                    <td><strong>${total}</strong></td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default PreviousSummaries;
