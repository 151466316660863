import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Function to calculate summary
const calculateSummary = (admissions = []) => {
  let summary = {
    totalCount: 0,
    grandTotal: 0,
    counts: {
      $15: { count: 0, total: 0 },
      $10: { count: 0, total: 0 },
      $5: { count: 0, total: 0 },
    },
  };

  if (Array.isArray(admissions)) {
    admissions.forEach((admission) => {
      if (admission && admission.type) {
        const admissionType = admission.type.replace('$', '');

        if (summary.counts[`$${admissionType}`]) {
          summary.counts[`$${admissionType}`].count += 1;
          summary.counts[`$${admissionType}`].total += parseInt(admissionType, 10);
        }
        summary.totalCount += 1;
        summary.grandTotal += parseInt(admissionType, 10);
      }
    });
  }

  return summary;
};

// AdmissionList component
const AdmissionList = ({ admissions, onDelete }) => {
  const [filteredAdmissions, setFilteredAdmissions] = useState(admissions);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // Filter admissions based on date range
  const filterAdmissions = useCallback(() => {
    if (!fromDate && !toDate) {
      setFilteredAdmissions(admissions);
      return;
    }

    const filtered = admissions.filter((admission) => {
      const admissionDate = new Date(admission.time);
      if (fromDate && admissionDate < fromDate) return false;
      if (toDate && admissionDate > toDate) return false;
      return true;
    });

    setFilteredAdmissions(filtered);
  }, [admissions, fromDate, toDate]);

  useEffect(() => {
    filterAdmissions();
  }, [admissions, filterAdmissions]);

  const summary = calculateSummary(filteredAdmissions || []);

  return (
    <div>
      <h2>All Admissions Summary</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Count</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>$15 Admissions</td>
            <td>{summary?.counts?.['$15']?.count || 0}</td>
            <td>${summary?.counts?.['$15']?.total || 0}</td>
          </tr>
          <tr>
            <td>$10 Admissions</td>
            <td>{summary?.counts?.['$10']?.count || 0}</td>
            <td>${summary?.counts?.['$10']?.total || 0}</td>
          </tr>
          <tr>
            <td>$5 Admissions</td>
            <td>{summary?.counts?.['$5']?.count || 0}</td>
            <td>${summary?.counts?.['$5']?.total || 0}</td>
          </tr>
          <tr>
            <td><strong>Grand Total</strong></td>
            <td><strong>{summary?.totalCount || 0}</strong></td>
            <td><strong>${summary?.grandTotal || 0}</strong></td>
          </tr>
        </tbody>
      </Table>

      <div>
        <h4>Filter by Date</h4>
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
          <div>
            <label>From (Date and Time)</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              showTimeSelect
              dateFormat="MM/dd/yyyy h:mm aa"
              placeholderText="Select from date"
            />
          </div>
          <div>
            <label>To (Date and Time)</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              showTimeSelect
              dateFormat="MM/dd/yyyy h:mm aa"
              placeholderText="Select to date"
            />
          </div>
        </div>
      </div>

      <h2>All Admissions</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredAdmissions.map((admission) => (
            <tr key={admission.id}>
              <td>{admission.type}</td>
              <td>{new Date(admission.time).toLocaleString()}</td>
              <td>
                <Button variant="danger" className="w-100 delete-button" onClick={() => onDelete(admission.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AdmissionList;
