import React from 'react';
import { Accordion, Table, Button } from 'react-bootstrap';

const TodaySummary = ({ admissions, summary, handleAddAdmission, handleDeleteAdmission, formatTime }) => {
  return (
    <>
      <div className="mb-4 button-container">
        <Button variant="primary" onClick={() => handleAddAdmission('$15', 'primary')} className="admission-button">
          Add $15 Admission
        </Button>{' '}
        <Button variant="secondary" onClick={() => handleAddAdmission('$10', 'secondary')} className="admission-button">
          Add $10 Admission
        </Button>{' '}
        <Button variant="success" onClick={() => handleAddAdmission('$5', 'success')} className="admission-button">
          Add $5 Admission
        </Button>
      </div>

      {summary && (
        <>
          <h2>Daily Summary</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Type</th>
                <th>Count</th>
                <th>Total Value</th>
              </tr>
            </thead>
            <tbody>
              {summary.summary.map((item) => (
                <tr key={item.type}>
                  <td>{item.type}</td>
                  <td>{item.count}</td>
                  <td>${item.total}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="2">
                  <strong>Grand Total</strong>
                </td>
                <td>
                  <strong>${summary.grandTotal}</strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}

      <h2>Admissions List</h2>

      <Accordion defaultActiveKey="0">
        {admissions.map((group, index) => {
          const groupTotal = group.reduce((total, admission) => total + parseInt(admission.type.replace('$', ''), 10), 0);
          const groupTime = formatTime(group[0].time);
          return (
            <Accordion.Item eventKey={String(index)} key={index}>
              <Accordion.Header>
                {group.length} Person Group - ${groupTotal} - {groupTime}
              </Accordion.Header>
              <Accordion.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.map((admission) => (
                      <tr key={admission.id}>
                        <td>{admission.type}</td>
                        <td>{new Date(admission.time).toLocaleString()}</td>
                        <td>
                          <Button
                            variant="danger"
                            className="w-100 delete-button"
                            onClick={() => handleDeleteAdmission(admission.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );
};

export default TodaySummary;
