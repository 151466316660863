import React, {useState, useEffect, useCallback} from 'react';
import {Container, Tabs, Tab, Alert} from 'react-bootstrap';
import AdmissionList from './AdmissionList';
import PreviousSummaries from './PreviousSummaries';
import TodaySummary from './TodaySummary';
import AutoRefresh from './AutoRefresh';
import KeepAlive from './KeepAlive';
import PageVisibility from './PageVisibility'; // Import new components
import './App.css';

function App() {
	const [admissions, setAdmissions] = useState([]);
	const [summary, setSummary] = useState(null);
	const [allAdmissions, setAllAdmissions] = useState([]);
	const [key, setKey] = useState('today');
	const [alert, setAlert] = useState({show: false, variant: '', message: ''});

	const refreshInterval = 30;

	const showAlert = useCallback((variant, message) => {
		setAlert({show: true, variant, message});
		setTimeout(() => setAlert({show: false, variant: '', message: ''}), 3000);
	}, []);

	const fetchAdmissions = useCallback(async () => {
		const response = await fetch('https://admissions.everyonespinballclub.workers.dev/api/admissions');
		const data = await response.json();
		setAdmissions(groupAdmissions(data));
	}, []);

	const fetchSummary = useCallback(async () => {
		const response = await fetch('https://admissions.everyonespinballclub.workers.dev/api/summary');
		const data = await response.json();
		setSummary(data);
	}, []);

	const fetchAllAdmissions = useCallback(async () => {
		const response = await fetch('https://admissions.everyonespinballclub.workers.dev/api/admissions/all');
		const data = await response.json();
		setAllAdmissions(data);
	}, []);

	const handleRefresh = useCallback(() => {
		if (key === 'today') {
			fetchAdmissions();
			fetchSummary();
		} else if (key === 'previous') {
			fetchAllAdmissions();
		} else if (key === 'all') {
			fetchAllAdmissions();
		}
	}, [key, fetchAdmissions, fetchSummary, fetchAllAdmissions]);

	useEffect(() => {
		handleRefresh();
	}, [handleRefresh]);

	const handleAddAdmission = async (type, variant) => {
		await fetch('https://admissions.everyonespinballclub.workers.dev/api/admissions', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({type}),
		});
		showAlert(variant, `${type} Admission Added`);
		handleRefresh();
	};

	const handleDeleteAdmission = async (id) => {
		await fetch(`https://admissions.everyonespinballclub.workers.dev/api/admissions/${id}`, {method: 'DELETE'});
		showAlert('danger', 'Admission Deleted');
		handleRefresh();
	};

	const groupAdmissions = (admissions) => {
		const sortedAdmissions = admissions.sort((a, b) => new Date(a.time) - new Date(b.time));
		const groups = [];
		let currentGroup = [];
		let lastTime = null;

		sortedAdmissions.forEach((admission) => {
			const currentTime = new Date(admission.time);
			if (lastTime && (currentTime - lastTime) / 1000 > 15) {
				groups.push(currentGroup);
				currentGroup = [];
			}
			currentGroup.push(admission);
			lastTime = currentTime;
		});

		if (currentGroup.length > 0) groups.push(currentGroup);
		return groups.reverse();
	};

	const formatTime = (dateString) => {
		const options = {hour: 'numeric', minute: 'numeric', hour12: true};
		return new Date(dateString).toLocaleTimeString([], options).toLowerCase();
	};

	return (
		<Container>
			<h1>Pinball Parlor Admissions</h1>

			{alert.show && (
				<div className="overlay-alert">
					<Alert variant={alert.variant} onClose={() => setAlert({show: false})} dismissible>
						{alert.message}
					</Alert>
				</div>
			)}

			<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-4">
				<Tab eventKey="today" title="Today's Summary">
					<TodaySummary admissions={admissions} summary={summary} handleAddAdmission={handleAddAdmission} handleDeleteAdmission={handleDeleteAdmission} formatTime={formatTime} />
				</Tab>

				<Tab eventKey="previous" title="Previous Days' Summaries">
					<PreviousSummaries allAdmissions={allAdmissions} />
				</Tab>

				<Tab eventKey="all" title="All Admissions">
					<AdmissionList admissions={allAdmissions} onDelete={handleDeleteAdmission} />
				</Tab>
			</Tabs>

			<AutoRefresh refreshInterval={refreshInterval} onRefresh={handleRefresh} />

			{/* Add KeepAlive and PageVisibility components */}
			<KeepAlive />
			<PageVisibility />
		</Container>
	);
}

export default App;
