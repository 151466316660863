import { useState, useEffect } from 'react';

const PageVisibility = () => {
  const [isVisible, setIsVisible] = useState(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      console.log('Page is visible, resuming operations...');
      // Resume or refresh the operations
    } else {
      console.log('Page is hidden, pausing operations...');
      // Pause or stop the operations
    }
  }, [isVisible]);

  return null; // No UI for this component
};

export default PageVisibility;
