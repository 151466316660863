import { useEffect } from 'react';

const KeepAlive = () => {
  useEffect(() => {
    const interval = setInterval(async () => {
      // Sending a simple GET request to keep the session alive
      try {
        await fetch('https://admissions.everyonespinballclub.workers.dev/api/keepalive');
        console.log('Session kept alive');
      } catch (error) {
        console.error('Error keeping the session alive:', error);
      }
    }, 5 * 60 * 1000); // Every 5 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return null; // No UI for this component
};

export default KeepAlive;
